@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700&family=Space+Mono:wght@400;700&display=swap');

:root{
  --bg-color: #F2EAE4;
  --bg-transparent: #f2eae4f1;
  --blue: #0063F2;
  --lite-blue: #8ADDFF;
  --orange: #FF8D19;
  --black: #161616;
}

html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--black);
}

a {
  color: var(--blue);
  text-decoration: none;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* SCROLL BAR */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background:  var(--bg-color);
}
::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--blue);
}