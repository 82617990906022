@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700&family=Space+Mono:wght@400;700&display=swap);
:root{
  --bg-color: #F2EAE4;
  --bg-transparent: #f2eae4f1;
  --blue: #0063F2;
  --lite-blue: #8ADDFF;
  --orange: #FF8D19;
  --black: #161616;
}

html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2EAE4;
  background-color: var(--bg-color);
  color: #161616;
  color: var(--black);
}

a {
  color: #0063F2;
  color: var(--blue);
  text-decoration: none;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* SCROLL BAR */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background:  #F2EAE4;
  background:  var(--bg-color);
}
::-webkit-scrollbar-thumb {
  background: #0063F2;
  background: var(--blue);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0063F2;
  background: var(--blue);
}
.header{
    position: fixed;
    width: 100vw;
    background-color: var(--bg-transparent);
    z-index: 100;
}

.header__container{
    display: flex;
    padding: 1.4rem 6vw;
    padding-bottom: 1rem;
    justify-content: space-between;

    align-items: center;
}

.heaeder__icon{
    height: 2.6rem;
}

.heaeder__logo{
    height: 2.6rem;
    display: none;
}

.header__text{
    color: var(--blue);
    font-family: 'Space Mono', monospace;
    font-size: smaller;
}

/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .header__container{
        justify-content: center;
    }

    .header__text{
        display: none;
    }

    .heaeder__logo{
        height: 2.6rem;
        display: block;
    }

    .heaeder__icon{
        display: none;
    }
    
}
.popup{
    width: 200px;
    height: auto;
    position: absolute;
    bottom: 30%;
}

/* Show only 1 popup on mobile */
@media only screen and (max-width: 450px) {
    .popup__2{
        display: none;
    }
}

.popup__dot{
	background-color: #0063F2;
	margin: 0 auto;
    height: 0;
  	width: 0;
    border-radius: 50%;
    
    animation-name: dot_animation, dot_animation;
    animation-duration: .7s;
    animation-delay: 0s, 7.3s;
    animation-fill-mode: forwards;
    animation-direction: normal, reverse;
}

@keyframes dot_animation {
    from {
        height: 0;
        width: 0;
    }
    to {
        height: 12px;
        width: 12px;
    }
}

.popup__line {
  border-left: 2px solid #0063F2;
  height: 0;
  width: 0;
  margin: 0 auto;
  
  animation-name: line_animation, line_animation;
  animation-duration: .5s;
  animation-delay: .6s, 7s;
  animation-fill-mode: forwards;
  animation-direction: normal, reverse;
}

@keyframes line_animation {
    from {
        height: 0;
    }
    to {
        height: 15px;
    }
}

.popup__price{
    box-shadow: 3px 3px 6px #0065f269;
	margin: 0 auto;
    height: 0;
    width: 0;
    border: 0px;
    background-color: #F2EBE5;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    animation-name: price__animation, price__animation;
    animation-duration: 1s;
    animation-delay: 1.2s, 6.2s;
    animation-fill-mode: forwards;
    animation-direction: normal, reverse;
}

@keyframes price__animation {
    0% {
        height: 0;
        width: 0;
        border: 0px;
    }
    1%{
        border: 2px solid #0063F2;
    }
    20% {
        height: 24px;
        border: 2px solid #0063F2;
    }
    100% {
        height: 24px;
        width: 200px;
        border: 2px solid #0063F2;
    }
}

.popup__price p{
	color: #0065f200;
    font-family: 'Space Mono', monospace;
	margin: 0 1rem;
    padding: 0;
    
    animation-name: price__text__animation, price__text__animation;
    animation-duration: .3s;
    animation-delay: 2s, 6s;
    animation-fill-mode: forwards;
    animation-direction: normal, reverse;
}

@keyframes price__text__animation {
    from {
        color: #0065f200;
    }
    to {
        color: #0065f2;
    }
}
.home{
    width: 100%;
    padding-bottom: 7rem;
    background-image: url(/static/media/bg1.56107d2f.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
}

.home__map__container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__map{
    width: 70vw;
    padding-top: 5rem;
}

.home__title{
    font-weight: 300;
    text-align: center;
    font-size: 36pt;
    margin-top: 0;
}

.home__button__container{
    text-align: center;
}

.home__button{
    cursor: pointer;
    background-color: var(--blue);
    color: white;
    padding: .2rem 3rem;
    border-radius: 2rem;
    border: 0;
    font-family: 'Space Mono', monospace;
    font-size: smaller;
    -webkit-appearance: none;
}


/* Tablet Styles */
@media only screen and (min-width: 451px) and (max-width: 1050px) {
    .home{
        height: 95vh;
        padding-bottom: 0;
    }

    .home__map__container{
        height: 60vh;
    }
    
    .home__map{
        width: 90vw;
        padding-top: 5rem;
    }   
}

/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .home{
        height: 95vh;
        padding-bottom: 0;
        background-size: 100% 80%;
    }

    .home__map__container{
        height: 60vh;
    }
    
    .home__map{
        width: 160vw;
        padding-top: 5rem;
    }   

    .home__title{
        font-weight: 300;
        text-align: center;
        font-size: 22pt;
        margin-top: 0;
    }
    
}
.about{
    padding: 5rem 6vw;
    display: flex;
    justify-content: space-between;
    background-color: #F8F5F3;
}

.about__title{
    font-weight: 300;
    font-size: 36pt;
    margin-top: 0;
}

.about__subtitle{
    padding-top: 1rem;
    font-weight: 400;
}

.about__text__container{
    width: 40vw;
    font-weight: 300;
    font-size: 15pt;
}

.about__text__container p{
    margin-top: 0;
}

.about__uptime__container{
    color: var(--orange);
    font-family: 'Space Mono', monospace;
}

.about__uptime__container h1{
    margin: 0;
    font-weight: 400;
    font-size: 98pt;
    text-align: left;
    line-height: 98pt;

}
.about__uptime__container h3{
    margin: 0;
    font-weight: 400;
    text-align: left;
    margin-left: .7rem;
}
@media only screen and (min-width: 451px) {
    .about__text__bottom{
        margin-bottom: 0;
    }
}
/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .about{
        padding: 5rem 6vw;
        display: block;
    }

    .about__title{
        text-align: center;
        font-size: 28pt;
    }

    .about__text__container{
        width: auto;
    }
    
    .about__uptime__container{
        text-align: center;
    }
    
}
.footer__about2__container{
    background-image: url(/static/media/bg2.943f0b45.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% auto;
}

.about2{
    display: flex;
    justify-content: space-around;
}

.about2__section{
    text-align: center;
    margin: 10rem 0;
}

.about2__section__title{
    color: var(--blue);
    font-size: 16pt;
    margin: 0;
}

.about2__section__text{
    font-weight: 300;
    font-size: 12pt;
}

/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .footer__about2__container{
        background-size: 150% 200%;
    }

    .about2{
        display: block;
        padding: 3rem 0;
    }

    .about2__section{
        margin: 3rem 0;
    }
}
.footer{
    padding: 1.8rem 6vw;
    background-color: #f8f5f38c;
}

.footer__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__logo{
    height: 2.2rem;
    padding-left: 7rem;
}

.footer__container p {
    font-size: 9pt;
    font-weight: 300;
}


/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .footer__container{
        margin-bottom: 1.3rem;
    }
    .footer__container p {
        margin: 0 auto;
        margin-top: 2.9rem;
        position: absolute;
        font-size: 8pt;
    }
}

