.footer{
    padding: 1.8rem 6vw;
    background-color: #f8f5f38c;
}

.footer__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__logo{
    height: 2.2rem;
    padding-left: 7rem;
}

.footer__container p {
    font-size: 9pt;
    font-weight: 300;
}


/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .footer__container{
        margin-bottom: 1.3rem;
    }
    .footer__container p {
        margin: 0 auto;
        margin-top: 2.9rem;
        position: absolute;
        font-size: 8pt;
    }
}