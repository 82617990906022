.header{
    position: fixed;
    width: 100vw;
    background-color: var(--bg-transparent);
    z-index: 100;
}

.header__container{
    display: flex;
    padding: 1.4rem 6vw;
    padding-bottom: 1rem;
    justify-content: space-between;

    align-items: center;
}

.heaeder__icon{
    height: 2.6rem;
}

.heaeder__logo{
    height: 2.6rem;
    display: none;
}

.header__text{
    color: var(--blue);
    font-family: 'Space Mono', monospace;
    font-size: smaller;
}

/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .header__container{
        justify-content: center;
    }

    .header__text{
        display: none;
    }

    .heaeder__logo{
        height: 2.6rem;
        display: block;
    }

    .heaeder__icon{
        display: none;
    }
    
}