.about{
    padding: 5rem 6vw;
    display: flex;
    justify-content: space-between;
    background-color: #F8F5F3;
}

.about__title{
    font-weight: 300;
    font-size: 36pt;
    margin-top: 0;
}

.about__subtitle{
    padding-top: 1rem;
    font-weight: 400;
}

.about__text__container{
    width: 40vw;
    font-weight: 300;
    font-size: 15pt;
}

.about__text__container p{
    margin-top: 0;
}

.about__uptime__container{
    color: var(--orange);
    font-family: 'Space Mono', monospace;
}

.about__uptime__container h1{
    margin: 0;
    font-weight: 400;
    font-size: 98pt;
    text-align: left;
    line-height: 98pt;

}
.about__uptime__container h3{
    margin: 0;
    font-weight: 400;
    text-align: left;
    margin-left: .7rem;
}
@media only screen and (min-width: 451px) {
    .about__text__bottom{
        margin-bottom: 0;
    }
}
/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .about{
        padding: 5rem 6vw;
        display: block;
    }

    .about__title{
        text-align: center;
        font-size: 28pt;
    }

    .about__text__container{
        width: auto;
    }
    
    .about__uptime__container{
        text-align: center;
    }
    
}