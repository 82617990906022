.popup{
    width: 200px;
    height: auto;
    position: absolute;
    bottom: 30%;
}

/* Show only 1 popup on mobile */
@media only screen and (max-width: 450px) {
    .popup__2{
        display: none;
    }
}

.popup__dot{
	background-color: #0063F2;
	margin: 0 auto;
    height: 0;
  	width: 0;
    border-radius: 50%;
    
    animation-name: dot_animation, dot_animation;
    animation-duration: .7s;
    animation-delay: 0s, 7.3s;
    animation-fill-mode: forwards;
    animation-direction: normal, reverse;
}

@keyframes dot_animation {
    from {
        height: 0;
        width: 0;
    }
    to {
        height: 12px;
        width: 12px;
    }
}

.popup__line {
  border-left: 2px solid #0063F2;
  height: 0;
  width: 0;
  margin: 0 auto;
  
  animation-name: line_animation, line_animation;
  animation-duration: .5s;
  animation-delay: .6s, 7s;
  animation-fill-mode: forwards;
  animation-direction: normal, reverse;
}

@keyframes line_animation {
    from {
        height: 0;
    }
    to {
        height: 15px;
    }
}

.popup__price{
    box-shadow: 3px 3px 6px #0065f269;
	margin: 0 auto;
    height: 0;
    width: 0;
    border: 0px;
    background-color: #F2EBE5;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    animation-name: price__animation, price__animation;
    animation-duration: 1s;
    animation-delay: 1.2s, 6.2s;
    animation-fill-mode: forwards;
    animation-direction: normal, reverse;
}

@keyframes price__animation {
    0% {
        height: 0;
        width: 0;
        border: 0px;
    }
    1%{
        border: 2px solid #0063F2;
    }
    20% {
        height: 24px;
        border: 2px solid #0063F2;
    }
    100% {
        height: 24px;
        width: 200px;
        border: 2px solid #0063F2;
    }
}

.popup__price p{
	color: #0065f200;
    font-family: 'Space Mono', monospace;
	margin: 0 1rem;
    padding: 0;
    
    animation-name: price__text__animation, price__text__animation;
    animation-duration: .3s;
    animation-delay: 2s, 6s;
    animation-fill-mode: forwards;
    animation-direction: normal, reverse;
}

@keyframes price__text__animation {
    from {
        color: #0065f200;
    }
    to {
        color: #0065f2;
    }
}