.footer__about2__container{
    background-image: url(./bg2.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% auto;
}

.about2{
    display: flex;
    justify-content: space-around;
}

.about2__section{
    text-align: center;
    margin: 10rem 0;
}

.about2__section__title{
    color: var(--blue);
    font-size: 16pt;
    margin: 0;
}

.about2__section__text{
    font-weight: 300;
    font-size: 12pt;
}

/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .footer__about2__container{
        background-size: 150% 200%;
    }

    .about2{
        display: block;
        padding: 3rem 0;
    }

    .about2__section{
        margin: 3rem 0;
    }
}