.home{
    width: 100%;
    padding-bottom: 7rem;
    background-image: url(./bg1.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
}

.home__map__container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__map{
    width: 70vw;
    padding-top: 5rem;
}

.home__title{
    font-weight: 300;
    text-align: center;
    font-size: 36pt;
    margin-top: 0;
}

.home__button__container{
    text-align: center;
}

.home__button{
    cursor: pointer;
    background-color: var(--blue);
    color: white;
    padding: .2rem 3rem;
    border-radius: 2rem;
    border: 0;
    font-family: 'Space Mono', monospace;
    font-size: smaller;
    -webkit-appearance: none;
}


/* Tablet Styles */
@media only screen and (min-width: 451px) and (max-width: 1050px) {
    .home{
        height: 95vh;
        padding-bottom: 0;
    }

    .home__map__container{
        height: 60vh;
    }
    
    .home__map{
        width: 90vw;
        padding-top: 5rem;
    }   
}

/* Mobile Styles */
@media only screen and (max-width: 450px) {
    .home{
        height: 95vh;
        padding-bottom: 0;
        background-size: 100% 80%;
    }

    .home__map__container{
        height: 60vh;
    }
    
    .home__map{
        width: 160vw;
        padding-top: 5rem;
    }   

    .home__title{
        font-weight: 300;
        text-align: center;
        font-size: 22pt;
        margin-top: 0;
    }
    
}